import React from "react"
import styled from "styled-components"
import IconBeliefs from "./icons/IconBeliefs"

const Container = styled.div`
    padding: 3rem 0 4rem;
`
const Beliefs = styled.div`
    display: grid;
    gap: 2rem;
    max-width: 650px;
    margin: 3rem auto 6rem;

    details {
        border: 1px solid white;
        border-radius: 20px;
        transition: 0.2s;

        :hover {
            border: 1px solid var(--sparkle-red);

            .belief-title svg {
                fill: var(--sparkle-red);
            }
        }
        &[open] {
            summary > svg {
                transform: rotate(180deg);
            }
        }
    }
    summary {
        display: grid;
        grid-template-columns: 1fr 22px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        list-style: none;
        padding: 1.5rem;
        gap: 1rem;
        cursor: pointer;
        
        &::-webkit-details-marker {
            display: none;
        }
        svg {
            transition: 0.2s;
        }
        .belief-title {
            display: flex;
            align-items: center;
            gap: 1rem;

            p {
                font-size: 1.5rem;
            }
            svg {
                fill: #fff;
            }
        }
    }
    .belief-details {
            padding: 0 1.5rem 1.5rem;

            p.belief-content {
                font-size: 1rem;
                line-height: 1.4;
            }
    }


    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        margin-top: 1.5rem;
    }
`
const Quote = styled.blockquote`
    background: linear-gradient(87.54deg, rgba(239, 71, 101, 0.9) -6.39%, rgba(55, 144, 226, 0.85) 51.12%, rgba(37, 213, 161, 0.85) 98.07%);
    background-size: 400% 400%;
    border-radius: 10px;
    padding: 5rem 2rem;
    text-align: center;
    margin-top: 4rem;
    animation: gradient 8s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @media screen and (max-width: 900px) {
        text-align: left;
        padding: 2rem;
        p.quote-author {
            text-align: right;
        }
    }
    p.block-quote {
        font-size: 1.5rem;
        max-width: 650px;
        margin: 0 auto;
        margin-bottom: 1rem;

        @media screen and (max-width: 900px) {
            font-size: 1.25rem;
        }
    }
`
const beliefs = [
    {
        name: 'Accessibility',
        paragraph: ["We're committed to helping democratize the process of starting and growing a business and demystifying the tools and technology that can make this easier.", <br/>, <br/>, "We have a mission of creating a stronger and more inclusive economy, so we're particularly interested in supporting underestimated entrepreneurs, like women and people of color (the fastest growing segment of entrepreneurs)."]
    },
    {
        name: 'Sustainability',
        paragraph: ["Starting and maintaining a business can sometimes feel all about the hustle. But we believe in holistic growth both personally and professionally, because you must first sustain yourself before you can sustain your business.", <br/>, <br/>, "Community and self care is a foundational part of our platform and our company."]
    },
    {
        name: 'Community',
        paragraph: ["We Sparkle is a social enterprise and public benefit corporation, which means we care about more than just making a profit. We also care for people and the planet. ", <br/>, <br/>, "We support nonprofit organizations through our Rate to Donate program and by offering scholarships to entrepreneurs who can benefit from our platform but may not be able to afford a subscription at the time."]
    },
    {
        name: 'Collaboration',
        paragraph: ["Collaborating is a huge part of growing a business, and of We Sparkle! We are intentional about partnering with organizations who serve small business owners, like co-working spaces and entrepreneurship hubs.", <br/>, <br/>, "Feedback and support from our Sparklers have been essential to helping us grow and thrive. Serving them, our customers, and supporting their work is  what we love to do!"]
    },
]
const AboutBeliefs = () => {
    return(
        <div>
            <Container>
                <h2>Our Values</h2>
                <Beliefs>
                    {beliefs.map((item, i) => {
                        return (
                            <details key={i}>
                                <summary>
                                    <div className="belief-title">
                                        <IconBeliefs name={item.name} style={{ width: 40, height: 40 }} />
                                        <p>{item.name}</p>
                                    </div>
                                    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.25 11 6.25 6.25L19.75 11M26 13.5C26 20.404 20.404 26 13.5 26S1 20.404 1 13.5 6.596 1 13.5 1 26 6.596 26 13.5Z" stroke="#fff"/></svg>
                                </summary>
                                <div className="belief-details">
                                    <p className="belief-content">{item.paragraph}</p>
                                </div>
                            </details>
                        )
                    })}
                </Beliefs>
                <Quote>
                    <p className="block-quote"><strong>"For there is always light, if only we’re brave enough to see it. If only we’re brave enough to be it."</strong></p>
                    <p className="quote-author">- Amanda Gorman</p>
                </Quote>
            </Container>
        </div>
    )
}

export default AboutBeliefs