const PressList = [
  {
    publication: "Voyage Minnesota Magazine",
    headline: "Inspiring Conversations with Michelle Maryns of We Sparkle Co.",
    url: "https://voyageminnesota.com/interview/inspiring-conversations-with-michelle-maryns-of-we-sparkle-co/",
    description:
      "Today we’d like to introduce you to Michelle Maryns. Hi Michelle, thanks for sharing your story with us. To start, maybe you can tell our readers some of your backstory. I started We Sparkle Co. to help small businesses save time + increase sales. ",
    date: "June 2, 2022",
  },
  {
    publication: "Beta",
    headline: "Beta Alum WeSparkle Grows Team & Lands Investment",
    url: "https://blog.beta.mn/beta-alum-wesparkle-grows-team-and-lands-investment",
    description:
      "Just a few years after building out her tool to help underestimated entrepreneurs succeed, Michelle Maryns is growing the team, finishing up a seed round, and preparing for the next exciting stage for WeSparkle.",
    date: "Nov 29, 2021",
  },
  {
    publication: "Minne Inno",
    headline:
      "Fintech changed the financial world quickly. Here's what's next.",
    url: "https://www.bizjournals.com/twincities/inno/stories/inno-events/2021/06/01/state-of-innovation-fintech-minne-inno.html",
    description:
      "At Minne Inno's State of Innovation: Fintech event, three local Fintech startups pitched their products. Michelle Trans Maryns, owner of We Sparkle was one of the companies to pitch their ideas.",
    date: "June 01, 2021",
  },
  {
    publication: "StarTribune",
    headline:
      "Bush Foundation names 16 Minnesotans to competitive 2021 fellowship program",
    url: "https://www.startribune.com/bush-foundation-names-16-minnesotans-to-competitive-2021-fellowship-program/600055823/",
    description:
      "Michelle Trans Maryns and 17 other Minnesotans will participate in the Bush Fellowship program to pursue 'education and learning opportunities to help them drive change in their communities.'",
    date: "May 11, 2021",
  },
  {
    publication: "Twin Cities Business",
    headline: "Over Half of 2021 Bush Fellows Are Minnesotans",
    url: "https://tcbmag.com/over-half-of-2021-bush-fellows-are-minnesotans/",
    date: "May 11, 2021",
  },
  {
    publication: "Derby Informer.com",
    headline: "Former Derby resident wins $100k Bush Foundation fellowship",
    url: "http://www.derbyinformer.com/news/business/former-derby-resident-wins-100k-bush-foundation-fellowship/article_6e042232-b7f3-11eb-a3b2-7bcd3c89fad9.html",
    date: "May 18, 2021",
  },
  {
    publication: "StarTribune",
    headline:
      "Minnesota's tax credit for angel investors works, should become routine",
    url: "https://www.startribune.com/minnesota-s-tax-credit-for-angel-investors-works-should-become-routine/600022720/",
    date: "Feb 14, 2021",
  },
  {
    publication: "tech.mn",
    headline: "A Workday With Michelle Tran Maryns of We Sparkle",
    url: "https://tech.mn/news/2021/01/27/we-sparkle-michelle-tran-maryns/",
    description:
      "In a podcast interview with CEO and founder of We Sparkle, Michelle Maryns talks about what empowered her to start her own business. It all started when she raised money to help people in Hati after the earthquake crisis.",
    date: "Jan 27, 2021",
  },
  {
    publication: "tech.mn",
    headline:
      "The tech.mn Podcast: Building a Business with Balance with Michelle Maryns of We Sparkle",
    url: "https://tech.mn/news/2021/01/12/the-tech-mn-podcast-we-sparkle-michelle-maryns/",
    description:
      "In a podcast interview we hear about CEO and founder of We Sparkle, Michelle Maryns' workday. In this podcast we explore Maryns' rountines of what time she gets up and goes to bed, and how she gets ready for work.",
    date: "Jan 12, 2021",
  },
  {
    publication: "Minneapolis/St. Paul Business Journal",
    headline:
      "2020 Women in Business honorees updated with execs from Minnesota Vikings, Baker Tilly, Children's Minnesota, more",
    url: "https://www.bizjournals.com/twincities/news/2020/10/05/2020-women-in-business-round-one-profiles.html",
    date: "Oct 7, 2020",
  },
  {
    publication: "Minneapolis/St. Paul Business Journal",
    headline:
      "2020 Women in Business Awards: Michelle Tran Maryns, We Sparkle Co.",
    url: "https://www.bizjournals.com/twincities/news/2020/10/05/2020-women-in-business-michelle-tran-maryns.html",
    date: "Oct 5, 2020",
  },
  {
    publication: "MPR News",
    headline:
      "How are Minn.'s minority-owned businesses navigating pandemic, economic crisis?",
    url: "https://www.mprnews.org/episode/2020/09/14/davis-how-are-minnesotas-minority-businesses-navigating-the-pandemic-economic-crisis",
    date: "Sep 14, 2020",
  },
  {
    publication: "Minneapolis/St. Paul Business Journal",
    headline: "Think About Equity Before Your Launch",
    url: "https://tcbmag.com/think-about-equity-before-your-launch/",
    date: "Aug 31, 2020",
  },
  {
    publication: "Minneapolis/St. Paul Business Journal",
    headline:
      "Minneapolis’ entrepreneurs of color face unique challenges in raising venture capital",
    url: "https://www.bizjournals.com/twincities/news/2020/07/01/minneapolis-venture-capital-entrepreneurs-of-color.html",
    date: "Jul 1, 2020",
  },
  {
    publication: "Forbes",
    headline:
      "New Programs, Grads And $2M In Donated Profits At FINNovation Lab And FINNEGANS Brew Co.",
    url: "https://www.prnewswire.com/news-releases/spectrum-virtual-community-of-multicultural-changemakers-convene-to-close-racial-equity-gap-301066939.html",
    date: "May 28, 2020",
  },
  {
    publication: "MinnPost",
    headline:
      "Is Minneapolis-St. Paul really the ‘Best Place for Female Entrepreneurs’?",
    url: "https://www.forbes.com/sites/annefield/2019/12/28/new-programs-grads-and-2m-in-donated-profits-at-finnovation-lab-and-finnegans-brew-co/?sh=6fcc1c84599c",
    date: "Dec 28, 2019",
  },
  {
    publication: "Minne Inno",
    headline: "6 Minnesota Social Impact Startups You Need to Know",
    url: "https://www.bizjournals.com/twincities/inno/stories/inno-insights/2019/11/25/6-minnesota-social-impact-startups-you-need-to.html",
    date: "Nov 25, 2019",
  },
  {
    publication: "MinnPost",
    headline:
      "Is Minneapolis-St. Paul really the ‘Best Place for Female Entrepreneurs’?",
    url: "https://www.minnpost.com/twin-cities-business/2019/11/is-minneapolis-st-paul-really-the-best-place-for-female-entrepreneurs/",
    date: "Nov 22, 2019",
  },
  {
    publication: "GREATER MSP",
    headline: "Regional Ambitions Stories",
    url: "https://www.youtube.com/watch?v=FxaotNmGw0A",
    date: "Nov 11, 2019",
  },
  {
    publication: "StarTribune",
    headline:
      "Two entrepreneurs in different trades put a compelling face on Minnesota business",
    url: "https://www.startribune.com/two-entrepreneurs-in-different-trades-put-a-compelling-face-on-minnesota-business/563332812/",
    date: "Oct 19, 2019",
  },
  {
    publication: "tech.mn",
    headline: "A Look Back At Twin Cities Startup Week 2020",
    url: "https://tech.mn/news/2020/10/19/twin-cities-startup-week-2020",
    date: "Oct 19, 2019",
  },
  {
    publication: "Pioneer Press",
    headline: "Business People: Jungbauer to lead Minnesota Chamber board",
    url: "https://www.twincities.com/2019/10/13/business-people-jungbauer-to-lead-minnesota-chamber-board/",
    date: "Oct 13, 2019",
  },
  {
    publication: "Twin Cities Business",
    headline:
      "TC Startup Week Day 2: Retail, Diversity, Philanthropy +Fertility",
    url: "https://tcbmag.com/tc-startup-week-day-2-retail-diversity-philanthropy-fertility/",
    date: "Oct 11, 2019",
  },
  {
    publication: "tech.mn",
    headline: "BETA Startup Showcase: We Sparkle",
    url: "https://tech.mn/news/2019/10/10/beta-startup-showcase-we-sparkle",
    date: "Oct 10, 2019",
  },
  {
    publication: "Minnesota Monthly",
    headline: "5 Style Startups to Follow",
    url: "https://www.minnesotamonthly.com/lifestyle/style-shopping/5-style-startups-to-follow/",
    date: "Oct 7, 2019",
  },
  {
    publication: "Pioneer Press",
    headline:
      "Startup Showcase: Her software tool can help small-business owners focus",
    url: "https://www.twincities.com/2019/10/06/startup-showcase-her-software-tool-can-help-small-business-owners-focus/",
    date: "Oct 6, 2019",
  },
  {
    publication: "Minne Inno",
    headline: "Minnesota Cup Announces 2019 Division Winners",
    url: "https://www.bizjournals.com/twincities/inno/stories/news/2019/09/20/minnesota-cup-announces-2019-division-winners.html",
    date: "Sep 20, 2019",
  },
  {
    publication: "Twin Cities Business",
    headline: "MN Cup Names 2019 Division Finalists",
    url: "https://tcbmag.com/mn-cup-names-2019-division-finalists/",
    date: "Aug 27, 2019",
  },
  {
    publication: "Minne Inno",
    headline: "MN Cup Names 28 Finalists in 2019 Competition",
    url: "https://www.bizjournals.com/twincities/inno/stories/news/2019/08/27/mn-cup-names-28-finalists-in-2019-competition.html",
    date: "August 27, 2019",
  },
  {
    publication: "Minneapolis/St. Paul Business Journal",
    headline: "Here are the 90 startups competing in the 2019 Minnesota Cup",
    url: "https://www.bizjournals.com/twincities/news/2019/05/30/here-are-the-90-startups-competing-in-the-2019.html",
    date: "May 30, 2019",
  },
  {
    publication: "StarTribune",
    headline:
      "Finnegans 'Finnovation Lab' launches first cohort of social entrepreneurs",
    url: "https://www.startribune.com/finnegans-finnovation-lab-launches-first-cohort-of-social-entrepreneurs/510347272/",
    date: "May 25, 2019",
  },
  {
    publication: "Twin Cities Business",
    headline: "Social Entrepreneurism Drives Next Class of Startups",
    url: "https://tcbmag.com/social-entrepreneurism-drives-next-class-of-startups/",
    date: "April 23, 2019",
  },
  {
    publication: "Junior Achievement North",
    headline: "2019 JAUM Company of the Year",
    url: "https://www.janorth.org/2019-jaum-company-of-the-year/",
    date: "April 18, 2019",
  },
  {
    publication: "Mill City Times",
    headline:
      "FINNOVATION Lab Expands Fellowship Program, Accepting Applications for Second Cohort of Entrepreneurs",
    url: "http://millcitytimes.com/news/finnovation-lab-expands-fellowship-program-accepting-applica.html",
    date: "April 17, 2019",
  },
  {
    publication: "Pollen",
    headline: "FINNOVATION Lab Names Inaugural FINNOVATION Fellows",
    url: "https://www.pollenmidwest.org/opportunities/finnovation-lab-names-inaugural-finnovation-fellows/",
    date: "Oct 31, 2018",
  },
  {
    publication: "Pioneer Press",
    headline: "Business People: Sunday, Sept. 23",
    url: "https://www.twincities.com/2018/09/22/business-people-sunday-sept-23/",
    date: "Sep 22, 2018",
  },
  {
    publication: "tonyloyd",
    headline: "Meet the FINNOVATION Fellows, with Katrina Becker",
    url: "https://tonyloyd.com/meet-the-finnovation-fellows-with-katrina-becker/",
    date: "Sep 16, 2018",
  },
  {
    publication: "Minne Inno",
    headline: "Meet Impact Hub's First Finnovation Fellows",
    url: "https://www.bizjournals.com/twincities/inno/stories/profiles/2018/09/11/meet-impact-hubs-first-finnovation-fellows.html",
    date: "Sep 11, 2018",
  },
]

export default PressList
