import React from "react"
import Seo from "../core/Seo"
import AboutLayout from "../about/Page"
import { ShowHideModalProvider } from "../homepage/useShowHideModalContext"
import { ThemeProvider } from "styled-components"

const AboutPage = () => {
  return (
    <ThemeProvider theme={{ colors: { accent: "#3790E2" } }}>
      <ShowHideModalProvider>
        <Seo title="We Sparkle • Simple Tools for Solopreneurs" includeZohoTracking />
        <AboutLayout />
      </ShowHideModalProvider>
    </ThemeProvider>
  )
}

export default AboutPage