import React, { useEffect, useState, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { ButtonBase } from "../homepage/Buttons"
import IconSparkle from "../homepage/icons/IconSparkle"
import RecipientCards from "./RecipientCards"
import { StaticImage } from "gatsby-plugin-image"
import { segmentAction } from "../hooks/useSegmentTrack"

const Container = styled.div`
  padding: 4rem 0;
`
const twinkle = keyframes`
  from {
    opacity: .3;
    transform: scale(.7) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }
`
const Sparkle = styled(IconSparkle)`
  height: auto;
  width: 2rem;
  animation: 1.5s ${twinkle} both alternate infinite;
`
const Link = styled(ButtonBase)`
  position: relative;
  transform: translateZ(0);
  width: auto;
  opacity: 1;
  color: white !important;
  padding: 1rem 1.25rem;
  font-weight: bold;
  font-size: 1rem;
  background: ${({ bg }) => bg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 100px;
  margin: 1.5rem 0rem 0rem;
`
const Header = styled.div`
  position: relative;
  text-align: center;
  max-width: 650px;
  margin: 0 auto 5rem;

  .sparkle-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: -3rem;
    left: 50%;
  }
  h2 {
    margin-bottom: 1.5rem;
  }
`
const NonProfits = styled.div`
  margin-bottom: 5rem;
  h3 {
    margin-bottom: 2rem;
  }
`
const RecipientGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
const Scholarships = styled.div`
  h3 {
    margin-bottom: 1.5rem;
  }
`
const ScholarshipContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;

  p {
    font-size: 1rem;
    line-height: 1.4;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`
const recipients = [
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/Esperanza-Logo.png"
        className={"img"}
        alt={"Esperanza United Logo"}
      />
    ),
    description:
      "Mobilizes Latinas and Latin@ communities to end gender-based violence",
    url: "https://esperanzaunited.org/en/",
    name: "Esperanza United",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/EverydayMiracles-Logo.png"
        className={"img"}
        alt={"Everyday Miracles Logo"}
      />
    ),
    description:
      "Working to reduce health disparities and improving outcomes for families during the pregnancy year",
    url: "https://www.everyday-miracles.org/",
    name: "Everyday Miracles",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/Femmecubator-Logo.png"
        className={"img"}
        alt={"Femmecubator Logo"}
      />
    ),
    description:
      "Empowering women of color as they navigate their next steps in tech, through mentorship and community",
    url: "https://www.femmecubator.com/",
    name: "Femmecubator",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/Junior-Achievement-North-Logo.png"
        className={"img"}
        alt={"Junior Achievement North Logo"}
      />
    ),
    description:
      "Inspiring and preparing young people to succeed in a global economy.",
    url: "https://www.janorth.org/",
    name: "Junior Achievement North",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/LedByTruth-Logo.png"
        className={"img"}
        alt={"Led By Truth Logo"}
      />
    ),
    description:
      "Led by Truth is a non-profit organization that creates a growth-oriented space for BIPOC professionals to thrive",
    url: "https://ledbytruth.org/",
    name: "Led by Truth",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/LOTT-Logo.png"
        className={"img"}
        alt={"LOTT Logo"}
      />
    ),
    description:
      "Challenging women to see their role in furthering women’s leadership in all sectors of society",
    url: "https://www.wearelott.org/",
    name: "LOTT",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/MHJN-Logo.png"
        className={"img"}
        alt={"MN Healing Justice Network Logo"}
      />
    ),
    description:
      "The Minnesota Healing Justice Network is an intergenerational community of healers and cultural workers that center Black and brown wellness through mutual aid, holistic care, and solidarity work",
    url: "https://www.mnhealingjustice.org/",
    name: "MN Healing Justice Network",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/ThePriceDynamic-Logo.png"
        className={"img"}
        alt={"The Price Dynamic Logo"}
      />
    ),
    description:
      "Inspiring communication that supports cooperative co-parenting and stabilization of families of color",
    url: "https://thepricedynamic.com/",
    name: "The Price Dynamic",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/YWCA-Logo.png"
        className={"img"}
        alt={"YWCA Logo"}
      />
    ),
    description:
      "Dedicated to eliminating racism, empowering women and promoting peace, justice, freedom and dignity for all.",
    url: "https://www.ywcastpaul.org/",
    name: "YWCA",
  },
]
const RateToDonate = ({ onChange }) => {
  const donateRef = useRef(null)
  const scholarshipRef = useRef(null)

  useEffect(() => {
    const { hash } = window.location
    if (hash === "#rate-to-donate") {
      donateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
    if (hash === "#scholarships") {
      scholarshipRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
  }, [])

  return (
    <Container id="rate-to-donate" ref={donateRef}>
      <Header>
        <div className="sparkle-icon" style={{ left: "44%", top: "-1.75rem" }}>
          <Sparkle
            style={{
              animationDelay: `${300}ms`,
              fill: "var(--sparkle-red)",
              width: "1.25rem",
            }}
          />
        </div>
        <div className="sparkle-icon" style={{}}>
          <Sparkle
            style={{
              animationDelay: `${500 - 300}ms`,
              fill: "var(--sparkle-brightblue)",
            }}
          />
        </div>
        <div className="sparkle-icon" style={{ left: "56%", top: "-2rem" }}>
          <Sparkle
            style={{
              animationDelay: `${1000 - 300}ms`,
              fill: "var(--sparkle-green)",
              width: "1rem",
            }}
          />
        </div>
        <h2>Rate to Donate Program</h2>
        <p>
          We asked our We Sparkle community Sparklers to nominate outstanding
          nonprofits to participate in our special Rate to Donate program, in
          which we donate $1 or an hour of volunteer time every time someone
          leaves a rating or a review for one of our Sparklers.
        </p>
      </Header>
      <NonProfits>
        <h3>Featured Nonprofits</h3>
        <RecipientGrid>
          <RecipientCards recipients={recipients} />
        </RecipientGrid>
      </NonProfits>
      <Scholarships id="scholarships" ref={scholarshipRef}>
        <h3>Community Scholarships</h3>
        <ScholarshipContent>
          <div>
            <p>
              We Sparkle is deeply committed to being part of creating a more
              equitable, diverse, and just economy.
              <br />
              <br />
              We encourage you to apply for a Sparkle Scholarship. Recipients
              will be awarded a complimentary Sparkle Assistant subscription,
              and a one-on-one coaching session with a Sparkle team member.
            </p>
          </div>
          <div>
            <p>
              Are you a BIPOC professional that is launching or growing your
              business?
              <br />
              <br />
              Are you doing community work to make the world better?
            </p>
            <Link
              as={"a"}
              target={"_blank"}
              href="https://zfrmz.com/lFLbcnUtigYEjnWRfRXC"
              bg="var(--sparkle-red)"
              onClick={(i) => {
                segmentAction.track(`Community Scholarship Click`)
                // onChange(i)
              }}
            >
              Apply for a Scholarship
            </Link>
          </div>
        </ScholarshipContent>
      </Scholarships>
    </Container>
  )
}

export default RateToDonate
