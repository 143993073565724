import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"
import IconSocials from "./icons/IconSocials"

const Container = styled.div`
  padding: 4rem 0;
`
const Bio = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  margin: 4rem 0 3rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }
`
const Image = styled.div`
  position: relative;
`
const Headshot = styled.div`
  max-width: 250px;
  margin: 0 auto;
`
const PetPicture = styled.div`
  max-width: 250px;
  margin: 0 auto;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  h3 span {
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
  }
  h3.title {
    font-weight: normal;
    font-size: 1.25rem;
  }
`
const Socials = styled.div`
  padding: 1rem 0;
`
const SocialHover = styled.span`
  svg {
    fill: #fff;
    transition: 0.2s;

    &:hover {
      fill: var(--sparkle-red);
    }
  }
`
const About = styled.div`
  details {
    display: block;
    padding: 0rem;
    font-size: 1rem;

    summary {
      display: grid;
      grid-template-columns: 1fr 22px;
      justify-content: space-between;
      align-content: end;
      position: relative;
      list-style: none;
      padding: 1rem 0 0;
      gap: 1rem;
      cursor: pointer;

      &::-webkit-details-marker {
        display: none;
      }
      svg {
        transition: 0.2s;
      }
    }
    div {
      margin-top: 1rem;
      line-height: 1.4;
    }
    &[open] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`
const bios = [
  {
    headshot: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/michelle-tran-maryns_headshot.png"
        className={"img"}
        alt={"Michelle Tran Maryns Headshot"}
      />
    ),
    pet: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/michelle-dog.png"
        className={"img"}
        alt={"Michelle's Dog'"}
      />
    ),
    name: "Michelle Maryns",
    pronouns: "(She/Her)",
    title: "Founder & CEO",
    socials: [
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/maryns/",
      },
      {
        name: "sparklesite",
        url: "https://sprkl.es/we-sparkle",
      },
    ],
    bioSummary:
      "Michelle founded We Sparkle with the mission of building a stronger, more inclusive, and more just economy by equipping underestimated entrepreneurs with the tools they need to succeed.",
    bioDetails: [
      "Michelle's career was deeply inspired by her mother's experiences. The owner of a thriving fabric business in Vietnam, she later came to the United States as a refugee and didn’t feel confident as a business owner. Her career choices were limited, and she experienced a host of discrimination and challenges as an immigrant and woman of color. Michelle always wondered what her mom could have accomplished if she had continued with her business.",
      <br />,
      <br />,
      "Michelle has been widely recognized as a leader and connector, and has been honored as a 2021 Bush Fellow, a Minneapolis/St. Paul Business Journal Women in Business Honoree, as well as being honored with Fellowships by the Global Good Fund, FINNOVATION Lab, and Luminary x United for America. She is also the Entrepreneur in Residence for Junior Achievement North, and serves on the board of a number of organizations supporting entrepreneurs in the community.",
      <br />,
      <br />,
      "Prior to launching We Sparkle, Michelle was most recently the Chief of Strategic Initiatives of MEDA - a Community Development Financial Institution (CDFI) named by the U.S. Department of Commerce as the top minority business development agency four years in a row. While there, she was able to help thousands of entrepreneurs begin and grow their businesses. We Sparkle gives her the opportunity to continue this work on an even larger scale.",
    ],
  },
  {
    headshot: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/nick-weber_headshot.png"
        className={"img"}
        alt={"Nick Weber Headshot"}
      />
    ),
    pet: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/nick-dog.png"
        className={"img"}
        alt={"Nick's Pet'"}
      />
    ),
    name: "Nick Weber",
    pronouns: "(He/Him)",
    title: "Co-Founder & CTO",
    socials: [
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/nicholas-weber-6a58a120/",
      },
      {
        name: "sparklesite",
        url: "https://sprkl.es/we-sparkle-co-2",
      },
    ],
    bioSummary:
      "Nick leads and oversees all technical aspects of We Sparkle. With degrees in psychology and industrial technology, and a background in mortgage banking, his pathway to a career in technology was a bit atypical, and his diverse experience informs his perspective on the work we do at We Sparkle - as does his experience growing up as the child of a small business owner.",
    bioDetails: [
      "As a young divorced mother of two without a high school degree,  Nick’s mom ran her own house cleaning business by day and waitressed at night. Nick is intimately familiar with the challenges of building and growing a business, 'Helping people find a path towards creating a rewarding career, that also allows them to support their families in a sustainable way, is something I am proud to be part of.'",
      <br />,
      <br />,
      "Nick is passionate about creating space, and personally mentoring underrepresented folks to diversify the tech field. He is also a big supporter of organizations like Eau Claire County Humane Association that help rescue animals.",
      <br />,
      <br />,
      "When he is not working, you will find him with Cecily, his wife and partner of more than 22 years, and Ellie Beans, their 70-pound Australian Cattle Dog mix that occasionally likes to sit atop his shoulders while working – (adorable) photographic evidence available upon request.",
    ],
  },
  {
    headshot: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/melissa-gutierrez-nelson_headshot.png"
        className={"img"}
        alt={"Melissa Gutierrez Nelson Headshot"}
      />
    ),
    pet: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/melissa-dog.png"
        className={"img"}
        alt={"Melissa's Pet'"}
      />
    ),
    name: "Melissa Gutierrez Nelson",
    pronouns: "(She/Her)",
    title: "Head of Community",
    socials: [
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/melissa-gutierrez-nelson/",
      },
      {
        name: "sparklesite",
        url: "https://sprkl.es/mplsmelissa/?preview=true#book-session",
      },
    ],
    bioSummary:
      "In her life and in her work, Melissa fiercely believes in the adage that “we all do better when we all do better.” She is immensely grateful to be part of a team whose work is guided by this same value.",
    bioDetails: [
      "As We Sparkle’s Head of Community, Melissa assists in facilitating partnerships, leads community initiatives (like our Rate to Donate and BIPOC Scholarship Programs) and our social media efforts. Melissa is also the primary connection to our customers. She finds their innovative spirits and dedication a continual source of inspiration. 'We have so many customers with incredible ideas, but who need just a bit of help to launch them into the world. I love that We Sparkle can be a part of people turning those ideas and intentions into action.'",
      <br />,
      <br />,
      "She brings her background in communications and her perspectives as a small business owner, a nonprofit manager, and the daughter of immigrants to her work with We Sparkle.",
      <br />,
      <br />,
      "Melissa is involved with many community organizations including Everyday Miracles, The Minnesota Breastfeeding Coalition, and The Minnesota Healing Justice Network.",
      <br />,
      <br />,
      "Her deepest joy is spending time with her family: Trent, her husband of over 25 years, their three grown children and their partners, and their miniature AussieDoodle named Lucifer. You will often find her in the stands at Hamline University basketball games cheering on her second born, teaching prenatal yoga and mindfulness, or childbirth education, or in her kitchen trying to hone her baking and vegan cooking skills.",
    ],
  },
]
const LeadershipTeam = () => {
  const [petImage, setPetImage] = useState(-1)
  const leadershipRef = useRef(null)

  useEffect(() => {
    const { hash } = window.location
    if (hash === "#leadership") {
      leadershipRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        offsetTop: 450,
      })
    }
  }, [])

  return (
    <div>
      <Container id="leadership" ref={leadershipRef}>
        <h2>Our Leadership Team</h2>
        {bios.map((person, i) => {
          return (
            <Bio key={person.name + i}>
              <Image
                onMouseOver={() => setPetImage(i)}
                onMouseLeave={() => setPetImage(-1)}
              >
                {petImage !== i ? (
                  <Headshot>{person.headshot}</Headshot>
                ) : (
                  <PetPicture>{person.pet}</PetPicture>
                )}
              </Image>
              <Content>
                <h3>
                  {person.name} <span>{person.pronouns}</span>
                </h3>
                <h3 className="title">{person.title}</h3>
                <Socials>
                  {person.socials.map((social, i) => {
                    return (
                      <SocialHover
                        key={i}
                        onClick={() => {
                          segmentAction.track(`Homepage Social Media Click`, {
                            value: social.name,
                          })
                        }}
                        style={{ marginRight: 20 }}
                        as={"a"}
                        href={social.url}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <IconSocials
                          name={social.name}
                          style={{ width: 25, height: 25 }}
                        />
                      </SocialHover>
                    )
                  })}
                </Socials>
                <About>
                  <details>
                    <summary>
                      <p>{person.bioSummary}</p>
                      <svg
                        width="22"
                        height="22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m6 9 5 5 5-5m5 2c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z"
                          stroke="#fff"
                        />
                      </svg>
                    </summary>
                    <div>
                      <p>{person.bioDetails}</p>
                    </div>
                  </details>
                </About>
              </Content>
            </Bio>
          )
        })}
      </Container>
    </div>
  )
}

export default LeadershipTeam
