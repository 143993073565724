import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"

import { TenantSignedInProvider } from "../hooks/useTenantSignedInContext"
import { TenantInfoProvider } from "../hooks/useTenantInfoContext"
import { SignUpProvider } from "../homepage/useSignUpContext"

import GlobalStyles from "../homepage/GlobalStyles"

import Homepage from "../homepage/Page"
import "../homepage/layout.css"
import PageNav from "../homepage/PageNav"
import AboutHero from "../about/AboutHero"
import AboutBeliefs from "../about/AboutBeliefs"
import LeadershipTeam from "../about/LeadershipTeam"
import RateToDonate from "../about/RateToDonate"
import PressCallout from "./PressCallout"
import useShowHideModalContext from "../homepage/useShowHideModalContext"
import PageContact from "../homepage/PageContact"
// import { segmentAction } from "../hooks/useSegmentTrack"
// import PageModalPlan from "../homepage/PageModalPlan"
// import ScholarshipForm from "./ScholarshipForm"

const Section = styled.section`
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }
  line-height: 1.3;
  font-size: 1.25rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 4.5rem;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.4;
    h1 {
      font-size: 2.25rem;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-drkblue);
        color: #fff;
    `
  }}
`
const Content = styled.div`
  padding: 8rem var(--content-pad);
  max-width: 900px;
  margin: auto;

  h2 {
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
  }
`
const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Page = () => {
  const [modal, setModal] = useShowHideModalContext()

  return (
    <ThemeProvider theme={{ color: { accent: "#3790E2" } }}>
      <TenantInfoProvider>
        <TenantSignedInProvider>
          <SignUpProvider>
            <GlobalStyles />
            <PageNav
              links={[
                { name: "Features", href: "/pricing#features" },
                { name: "Pricing", href: "/pricing#top" },
                { name: "Our Story", href: "/about" },
                { name: "Contact", href: "/contact" },
              ]}
            />
            <Section dark>
              <Content>
                <AboutHero />
                <AboutBeliefs />
                <LeadershipTeam />
                <RateToDonate
                // onChange={(i) => {
                //   setModal(true)
                //   }}
                />
                <PressCallout />
              </Content>
            </Section>
            <Section dark id={"follow-for-more"} as={"footer"}>
              <Content style={{ padding: "var(--content-pad)" }}>
                <PageContact />
              </Content>
            </Section>

            {/* Page Modal for Zoho Form */}
            {/* <PageModalPlan show={modal} onClose={() => setModal(false)}>
              <Section dark style={{ width: "100%" }}>
                  <Content style={{ position: "relative" }}>
                  <BackBtn
                      onClick={() => {
                      segmentAction.track("Scholarship Modal Close")
                      setModal(false)
                      }}
                  >
                      <svg
                      viewBox="0 0 100 100"
                      style={{
                          fill: "var(--sparkle-drkblue)",
                          width: 36,
                          transform: "translateX(-7.5%)",
                          height: "auto",
                      }}
                      >
                      <path d="M66.43,20a4,4,0,0,0-5.66,0L33.58,47.17a4,4,0,0,0,0,5.66L60.77,80a4,4,0,1,0,5.65-5.66L42.06,50,66.42,25.66A4,4,0,0,0,66.43,20Z"></path>
                      </svg>
                  </BackBtn>
                  <h2 style={{ marginTop: "2rem", marginBottom: ".5rem" }}>
                      Community Scholarship
                  </h2>
                  <p>We Sparkle is committed to helping create a stronger and more diverse economy and democratizing the process of starting a business.
                    <br/><br/>
                    We are particularly interested in supporting the work of underrepresented entrepreneurs, women, and people of color.
                    <br/><br/>
                    Are you a business owner, solopreneur, side hustler, visionary ...?!? Could you use We Sparkle to grow your business? Tell us how and you could be gifted a year of We Sparkle, on us! You'll also have access to our monthly business support sessions, be highlighted on our social media channels.
                  </p>
                  <ScholarshipForm/>
                  </Content>
              </Section>
            </PageModalPlan> */}
          </SignUpProvider>
        </TenantSignedInProvider>
      </TenantInfoProvider>
    </ThemeProvider>
  )
}

export default Page
