import React from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"
import { Link } from "gatsby"
import FeaturedPressCard from "./FeaturedPressCard"

const FeaturedPress = styled.div`
    padding: 4rem 0;

    h2 {
        margin-bottom: 4rem;
    }
`
const FeaturedPressGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const PressCallout = () => {
    return (
        <FeaturedPress>
            <h2>We Sparkle in the News</h2>
            <FeaturedPressGrid>
                <FeaturedPressCard/>
            </FeaturedPressGrid>
            <Link 
            to="/press" 
            target={"_blank"}
            style={{color: "white", textAlign: "center", display: "block"}}
            onClick={() => {
                segmentAction.track(`About Page Press Page Click`)
            }}
            className="show-feature-list">View All Press & Publicity →</Link>
        </FeaturedPress>
    )
}

export default PressCallout